import { useCallback, useEffect } from 'react';
import { getWidgetData } from 'src/api/get-widget-data';
import { getEntityId } from 'src/checkout-frame/api/get-entity-id';
import { ACTIONS } from 'src/constants/common';
import { Actions } from 'src/context/store/store-reducer';
import { ExperimentParams } from 'src/types/experiment';

export const useWidgetData = ({
  dispatch,
  ...rest
}: ExperimentParams & {
  amount?: number;
  webview?: boolean;
  dispatch: React.Dispatch<Actions>;
}) => {
  const { publicKey, merchantCode, currency, amount, webview } = Object.entries(
    rest
  ).reduce<typeof rest>((acc, [key, value]) => {
    if (value !== '') {
      return {
        ...acc,
        [key]: value,
      };
    }

    return acc;
  }, {});

  const getData = useCallback(async () => {
    const entityId = await getEntityId();

    const data = await getWidgetData({
      ab: {
        entityId,
        entity: 'customer',
        platform: 'web',
        filters: {
          merchantId: publicKey,
          merchantCode,
          currency,
        },
      },
      publicKey,
      merchantCode,
      currency,
      amount,
      webview,
    });

    dispatch({
      type: ACTIONS.GET_WIDGET_DATA,
      payload: data,
    });
  }, [publicKey, merchantCode, currency, amount, dispatch, webview]);

  useEffect(() => {
    getData();
  }, [publicKey, merchantCode, currency, amount, dispatch, webview, getData]);
};
