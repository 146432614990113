import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import { findExperiment } from 'src/checkout-frame/utils/find-experiment';
import { ACTIONS } from 'src/constants/common';
import { Actions, State, storeReducer } from 'src/context/store/store-reducer';
import { useValuesToBoolean } from 'src/context/store/use-values-to-boolean';
import { useValuesToNumber } from 'src/context/store/use-values-to-number';
import { useWidgetData } from 'src/hooks/use-widget-data';
import { CardTheme } from 'src/types/card-theme';
import { Currency } from 'src/types/currency';
import { Experiment } from 'src/types/experiment';
import { InfoAttrName } from 'src/types/info-attr-name';
import { Lang, Locale } from 'src/types/lang-locale-type';
import { ProductType } from 'src/types/product-type';
import { Size } from 'src/types/size';
import { StyleConfig } from 'src/types/style-config';
import { ThemeType } from 'src/types/theme-type';
import { WidgetOffers } from 'src/types/widget-offers';
import { checkCurrency } from 'src/utils/check-currency';
import { checkInstallmentsCount } from 'src/utils/check-installments-count';
import { checkLang } from 'src/utils/check-lang';
import { checkLocale } from 'src/utils/check-locale';
import { checkPrice } from 'src/utils/check-price';
import { checkProductType } from 'src/utils/check-product-type';
import { checkTheme } from 'src/utils/check-theme';

export interface MerchantProps<T extends ThemeType | CardTheme = ThemeType> {
  lang: Lang;
  locale: Locale;
  currency: Currency;
  price: number;
  installmentsCount: number;
  publicKey?: string;
  merchantCode?: string;
  partnerCharges?: boolean;
  styleConfig?: Partial<StyleConfig>;
  /** Options for background color inheritting */
  shouldInheritBg?: boolean;
  isBig?: boolean;
  isDark?: boolean;
  productType?: ProductType;
  theme?: T;
  woo?: boolean;
  merchant?: string;
  minPrice?: number;
  minLimit?: number;
  size?: Size;
  titles?: string[];
  infoAttrName?: InfoAttrName;
}

export type StoreContextProps<T extends ThemeType | CardTheme = ThemeType> =
  MerchantProps<T> & {
    isRtl: boolean;
    tabbyInfo?: 'payLater';
    currencyRaw?: Currency;
    priceRaw?: number;
    isBold?: boolean;
    isMobileRounded?: boolean;
    isShortSplitIt?: boolean;
    experiments: Experiment[];
    offers: WidgetOffers;
    aid?: string;
    loading: boolean;
    infoAttrName?: InfoAttrName;
    typeOfContentPopup?: 'schedule' | 'calculator' | 'default';
    dispatch: React.Dispatch<Actions>;
    getExperiment: typeof findExperiment;
  };

export const defaultValue = {} as StoreContextProps;

const StoreContext = React.createContext<StoreContextProps>(defaultValue);

StoreContext.displayName = 'StoreContext';

export const useAppContext = <T extends ThemeType | CardTheme>() =>
  React.useContext<StoreContextProps<T>>(
    StoreContext as any as React.Context<StoreContextProps<T>>
  );

export const INSTALLMENTS_COUNT_DEFAULT = 4;
export const INSTALLMENTS_COUNT_MAX = 12;

type InitialStateProps = Omit<
  Partial<MerchantProps> & { webview?: boolean },
  'merchant' | 'woo' | 'infoAttrName' | 'productType' | 'theme'
>;

const getInitialState = ({
  lang,
  locale,
  currency,
  price,
  installmentsCount,
  ...other
}: InitialStateProps): State => ({
  lang: checkLang(lang, {
    locale,
  }),
  locale: checkLocale(locale, { lang }),
  currency: checkCurrency(currency),
  currencyRaw: currency,
  price: checkPrice(price),
  priceRaw: price,
  installmentsCount: checkInstallmentsCount(INSTALLMENTS_COUNT_DEFAULT)(
    installmentsCount
  ),
  isBig: false,
  isDark: false,
  isBold: false,
  isMobileRounded: false,
  isShortSplitIt: false,
  experiments: [],
  aid: '',
  offers: { installments: [] },
  loading: true,
  ...other,
});

export function AppProvider<T extends ThemeType | CardTheme>({
  children,
  merchant = document.location.origin,
  infoAttrName,
  productType,
  theme,
  publicKey,
  merchantCode,
  styleConfig,
  size,
  ...propsRaw
}: Partial<MerchantProps<T>> & {
  children: ReactNode;
  webview?: boolean;
}) {
  const {
    woo,
    shouldInheritBg = true,
    partnerCharges,
    ...props
  } = useValuesToBoolean(propsRaw);
  const { minPrice, minLimit, ...values } = useValuesToNumber(props);

  const [
    {
      lang,
      locale,
      currency,
      price,
      installmentsCount,
      tabbyInfo,
      currencyRaw,
      priceRaw,
      isBig,
      isDark,
      titles,
      isBold,
      isMobileRounded,
      isShortSplitIt,
      typeOfContentPopup,
      webview,
      experiments,
      aid,
      offers,
      loading,
    },
    dispatch,
  ] = useReducer(storeReducer, values, getInitialState);

  useEffect(() => {
    dispatch({
      type: ACTIONS.UPDATE_STORE,
      payload: {
        isDark: values.isDark,
        isBig: values.isBig,
        titles: values.titles,
        installmentsCount: values.installmentsCount,
        price: values.price,
        currency: values.currency,
        lang: values.lang,
        locale: values.locale,
      },
    });
  }, [
    dispatch,
    values.isDark,
    values.isBig,
    values.titles,
    values.installmentsCount,
    values.price,
    values.currency,
    values.lang,
    values.locale,
  ]);

  useWidgetData({
    publicKey,
    merchantCode,
    currency,
    amount: price,
    webview,
    dispatch,
  });

  const getExperiment = useCallback<StoreContextProps['getExperiment']>(
    (experimentName) => findExperiment(experimentName, experiments),
    [experiments]
  );

  const providerValue = useMemo(
    () => ({
      isRtl: lang === Lang.ar,
      lang,
      locale,
      currency,
      price,
      productType: checkProductType(productType),
      theme: checkTheme(theme),
      installmentsCount,
      tabbyInfo,
      experiments,
      partnerCharges,
      publicKey,
      merchantCode,
      styleConfig,
      shouldInheritBg,
      woo,
      isBig,
      isDark,
      merchant,
      currencyRaw,
      priceRaw,
      dispatch,
      getExperiment,
      loading,
      offers,
      aid,
      size,
      minPrice: minPrice ?? minLimit,
      infoAttrName,
      titles,
      isBold,
      isMobileRounded,
      isShortSplitIt,
      typeOfContentPopup,
    }),
    [
      currency,
      experiments,
      getExperiment,
      installmentsCount,
      lang,
      locale,
      merchantCode,
      partnerCharges,
      price,
      productType,
      publicKey,
      shouldInheritBg,
      styleConfig,
      tabbyInfo,
      theme,
      woo,
      isBig,
      isDark,
      merchant,
      currencyRaw,
      priceRaw,
      loading,
      offers,
      aid,
      size,
      minPrice,
      minLimit,
      infoAttrName,
      titles,
      isBold,
      isMobileRounded,
      isShortSplitIt,
      typeOfContentPopup,
    ]
  );

  return (
    <StoreContext.Provider value={providerValue}>
      {children}
    </StoreContext.Provider>
  );
}
